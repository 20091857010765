<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'package',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/goods/farmProductCombo/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'comboName',
          type: 'input',
        },
        {
          name: '店铺名称',
          key: 'mainBodyName',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'comboName',
          title: '名称',
          align: 'left',
          width: '10%',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl.split(',')[0]} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        // {
        //   dataIndex: 'salePriceTotal',
        //   title: '原价',
        //   align: 'left',
        //   width: '10%',
        //   customRender: function (text, records) {
        //     return records.salePriceTotal == null ? '' : <div>{'￥' + records.salePriceTotal}</div>
        //   },
        //   sorter: (a, b) => a.salePriceTotal.length - b.salePriceTotal.length,
        // },
        {
          dataIndex: 'productCount',
          title: '套餐商品',
          align: 'center',
          width: '20%',
          sorter: (a, b) => a.productCount.length - b.productCount.length,
        },
        {
          dataIndex: 'mainBodyName',
          title: '店铺名称',
          align: 'left',
          width: '25%',
          sorter: (a, b) => a.mainBodyName.length - b.mainBodyName.length,
        },
        {
          dataIndex: 'joinFx',
          title: '是否分销',
          align: 'left',
          width: '15%',
          onExport: (records) => {
            return ['否', '是'][records]
          },
          filters: [
            {
              text: '否',
              value: '0',
            },
            {
              text: '是',
              value: '1',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.joinFx == value,
          customRender: (text) => {
            return text == '1' ? '是' : '否'
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          width: '90px',
          type: 'badge',
          align: 'center',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          align: 'left',
          fixed: 'right',
          width: 200,
          typeData: ({ records }) => {
            return [
              {
                dispaly:true,
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/goods/farmProductCombo/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                dispaly:records.syncFlag == 1,
                name: '同步价格',
                type: 'pop',
                popTitle: '确认同步价格吗?',
                onClick: () =>
                  api.command.syncGET.call(this, {
                    url: `/goods/farmProductCombo/syncPrice?id=${records.id}`,
                }),   
              },
              {
                dispaly:true,
                name: '日历价格',
                onClick: () =>
                  this.$router.push(
                    `/commodityManagement/panelCalendar?productId=${records.id}&productName=${records.comboName}&productType=3&preSellFlag=${records.preSellFlag}`
                  ),
              },
              {
                dispaly:true,
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/packageDetail?id=${records.id}`),
              },
              {
                dispaly:true,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmProductCombo?id=${records.id}`,
                  }),
              },
            ].filter(d=>d.dispaly)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/packageDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/goods/farmProductCombo/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmProductCombo/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmProductCombo/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
